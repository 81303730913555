html, body, #root, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
    background: #000000;
}

.app {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body > iframe {
    display: none;
}

html {
    background: #000000;
}

body {
    margin: 0;
    text-align: center;
    color: #fff;
    overflow-x: hidden;
}

p {
    margin: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #141414 inset !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #AAAAAA !important;
    caret-color: #AAAAAA;
}

html::-webkit-scrollbar {
    width: 6px;
    background: #303030;
}

html::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 4px;
    background-clip: content-box;
    background: linear-gradient(91.79deg, #BF1B73 3.15%, #FF5B25 85.66%);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
